<script>
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";

export default {
  components: { MainButtonIcon },

  props: {
    noticeType: {
      type: String,
      default: "info",
    },

    noticeClosed: {
      type: Boolean,
      default: false,
    },

    noticeGroup: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      default: "",
    },
  },

  computed: {
    showNoticeIcon() {
      return this.noticeType === "success" || this.noticeType === "error";
    },
  },

  mounted() {
    if (!this.noticeClosed) {
      setTimeout(() => {
        this.$emit("close");
      }, 8000);
    }
  },
};
</script>

<template>
  <div
    class="main-notice"
    :class="[
      { 'main-notice_auto-close': !noticeClosed },
      {
        'main-notice_certificate':
          noticeGroup === 'activity_application_approved' ||
          noticeGroup === 'activity_application_declined',
      },
    ]"
  >
    <MainButtonIcon
      v-if="noticeClosed"
      class="main-notice__button-close"
      @click="$emit('close')"
    >
      <span class="icon-close" />
    </MainButtonIcon>

    <div v-if="showNoticeIcon" class="main-notice__icon">
      <span v-if="noticeType === 'success'" class="icon-success" />

      <span v-if="noticeType === 'error'" class="icon-warning" />

      <span v-if="noticeType === 'info'" class="icon-info-thin" />
    </div>

    <div class="main-notice__content content">
      <h5
        class="content__title"
        :style="{ 'margin-bottom': description ? '4px' : '0' }"
      >
        {{ title }}
      </h5>
      <p class="content__description" v-html="description" />

      <slot />
    </div>

    <img
      v-if="
        noticeGroup === 'activity_application_approved' ||
        noticeGroup === 'activity_application_declined'
      "
      class="main-notice__certificate-notice-img"
      src="../../assets/images/certificate_notice.svg"
      alt=""
    />
  </div>
</template>

<style lang="scss" scoped>
.main-notice {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -100%;
  background-color: $light-primary;
  border-radius: 16px;
  box-shadow: $shadow-default;
  padding: 24px;
  z-index: 100099;
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  animation: show-notice 1s ease-out;
  animation-fill-mode: forwards;

  &_auto-close {
    animation: show-and-hide-notice 8s ease-in-out;
  }

  &__certificate-notice-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 120px;

    @media (max-width: 1023px) {
      width: 90px;
    }

    @media (max-width: 575px) {
      display: none;
    }
  }

  &__button-close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 24px;
    top: 24px;

    .icon-close {
      background-color: $dark-fifth;
      width: 24px;
      height: 24px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .icon-warning {
      width: 40px;
      height: 40px;
      background-color: $red;
    }

    .icon-success {
      width: 40px;
      height: 40px;
      background-color: $green-dark;
    }

    .icon-info-thin {
      width: 40px;
      height: 40px;
      background-color: $blue;
    }
  }

  .content {
    width: 100%;

    &__title {
      @include body-1;
      color: $dark-primary;
      width: calc(100% - 40px);
    }

    &__description {
      @include text-2;
      color: $dark-sixth;
      max-width: calc(100% - 40px);

      ::v-deep a {
        color: $blue;
        text-decoration: none;
      }
    }
  }

  &_certificate {
    .content {
      width: calc(100% - 160px);

      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 576px) {
    margin: 0 $space-xl;
    width: auto;
  }

  @media (max-width: 425px) {
    margin: 0 $space-l;
  }

  @media (max-width: 375px) {
    margin: 0 $space-m;
    animation: show-notice-mobile 1s ease-in-out;
    animation-fill-mode: forwards;

    &_auto-close {
      animation: show-and-hide-notice-mobile 8s ease-in-out;
    }
  }

  @keyframes show-notice {
    0% {
      bottom: -100%;
    }

    100% {
      bottom: 32px;
    }
  }

  @keyframes show-and-hide-notice {
    0% {
      bottom: -100%;
    }

    8% {
      bottom: 32px;
    }

    80% {
      bottom: 32px;
    }

    100% {
      bottom: -110%;
    }
  }

  @keyframes show-notice-mobile {
    0% {
      bottom: -100%;
    }

    100% {
      bottom: 24px;
    }
  }

  @keyframes show-and-hide-notice-mobile {
    0% {
      bottom: -110%;
    }

    8% {
      bottom: 24px;
    }

    80% {
      bottom: 24px;
    }

    100% {
      bottom: -110%;
    }
  }
}
</style>
