import API from "@/api";
import store from "./index";

const state = {
  tariff: null,
  tariffs: [],

  previewTariffPrice: null,
};

const getters = {};

const actions = {
  loadTariff({ commit }, tariffId) {
    return new Promise((resolve, reject) => {
      API.get(`api/tariffs/${tariffId}`)
        .then((response) => {
          commit("SET_TARIFF", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTariffs({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/tariffs")
        .then((response) => {
          commit("SET_TARIFFS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  buyTariff(_, { tariffId, certificates }) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/${tariffId}/buy`, {
        certificates: certificates.length ? certificates : null,
      })
        .then((response) => {
          resolve(response);

          localStorage.removeItem("certificates");
          store.dispatch("createNotification", {
            title: "Поздравляем с покупкой тарифа!",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "При покупке тарифа возникла ошибка!",
            description:
              "Попробуйте снова или обратитесь в техническую поддержку hello@rc.company",
            type: "error",
          });
        });
    });
  },

  buyTariffInstallment(_, tariffId) {
    return new Promise((resolve, reject) => {
      API.post(`/api/refill/${tariffId}/installment`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPreviewTariffPrice({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/${data.tariffId}/preview`, {
        certificates: data.certificates,
      })
        .then((response) => {
          commit("SET_PREVIEW_TARIFF_PRICE", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  disableTariffInstallment(_, tariffId) {
    return new Promise((resolve, reject) => {
      API.delete(`/api/tariffs/${tariffId}/installment`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TARIFF(state, payload) {
    state.tariff = payload;
  },

  SET_TARIFFS(state, payload) {
    state.tariffs = payload;
  },

  SET_PREVIEW_TARIFF_PRICE(state, payload) {
    state.previewTariffPrice = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
